import React from 'react'
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";

import '../CSS/FloatingIcon.css'
const FloatingIcon = () => {
  return (
    <div className='flot-icons'>
    <div>
  <a href="https://wa.me/+919987220795?txt" className="float-whatsapp whatsapp" target="_blank">
    <i className=" my-float"> <IoLogoWhatsapp /> </i>
  </a>

  <a href="https://www.instagram.com/shreebalajiresto.banquet/" className="float-instagram instagram" target="_blank">
    <i className=" my-float"> <IoLogoInstagram />
</i>
  </a>

  <a href="https://www.facebook.com/shreebalajirestobanquet/" className="float-facebook facebook" target="_blank">
    <i className=" my-float"> <FaFacebook /> </i>
  </a>
</div>

    </div>
  )
}

export default FloatingIcon