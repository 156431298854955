import React, { useState,useRef } from "react";
import "../CSS/Contact.css";
import Footer from "../Components/Footer";
import $ from "jquery";
import ServicesData from "../Components/ServicesData";
import MetaTags from 'react-meta-tags';
import EventsData from "../Components/EventsData";
import { IoIosCall } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaRegClock } from "react-icons/fa";
const Contact = (props) => {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custContact || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">SHREE BALAJI Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at SHREE BALAJI</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "shreebalajihospitality2703@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "SHREEBALAJI",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");

  };

  return (
    <>
      {/* ========= Contact Banner ====== */}
  
      <p className="d-none">
          Welcome to our best banquet near Bhayandar your gateway to extraordinary events and seamless celebrations. If you have inquiries, requests, or if you're ready to embark on your event planning journey with us, the Contact Us page is your direct link to personalized assistance.

Our dedicated team at our best banquet near Bhayandar, stands ready to assist you at every step of the way. Whether you're envisioning a dream wedding, corporate event, or any special celebration, we are here to turn your ideas into reality. Reach out to us via the provided contact information, and let us know how we can tailor our services to meet your specific needs.

Our commitment to excellence extends to our communication with you. Expect a prompt and courteous response as we address your queries and guide you through the process of creating an unforgettable event at [Banquet Hall Name].

Thank you for considering the best banquet near Bhayandar for your special occasions. We look forward to connecting with you and being a part of your journey to hosting memorable events that leave a lasting impression. Contact us today, and let the planning for your extraordinary celebration begin.

          </p>
      <div className="ContactBanner">
        <div className="container">
          <h1 className="ContactBanner-title">
          Best Banquet near Bhayandar
          </h1>
        </div>
      </div>
      
      {/* ========= Contact- Form ====== */}
      <div className="contact-area mt-5 mb-5">
        <div className="container">
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form">
                  <div className="contact-form-header">
                    <h2>Get In Touch</h2>
                 
                  </div>
                  <form
        method="post"
        ref={formRef}
        action=""
        id="contact-form"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name"
                onChange={(e) => setcustName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                onChange={(e) => setCustEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="number"
                name="contact"
                placeholder="Your Contact Number"
                className="form-control"
                onChange={(e) => setCustContact(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <select
                className="form-control"
                name="service"
                onChange={(e) => setCustSubject(e.target.value)}
              >
                <option value="">Choose Service</option>
                {ServicesData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
                {EventsData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <textarea
            name="message"
            cols={30}
            rows={5}
            className="form-control"
            placeholder="Write Your Message"
            onChange={(e) => setCustMessage(e.target.value)}
          />
        </div>
        {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button type="submit" className="theme-btn" onClick={ContactEnquiry}>
          Send Message <i className="far fa-paper-plane" />
        </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========= Contact-Area ====== */}
      <div className="contact-area mt-5">
        <div className="container">
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-content">
                  <div className="contact-info">
                    <div className="contact-info-icon">
                    <i>
                    <FaMapMarkerAlt className="fa fa-clock" />
                    </i>
                    </div>
                    <div className="contact-info-content">
                      <h5>Location</h5>
                      <p>
                     Balaji complex, next to flyover, 150 feet road, Bhayandar ( west )
                      </p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-icon">
                    <i>
                    <IoIosCall className="fa fa-clock" />
                    </i>
                    </div>
                    <div className="contact-info-content">
                      <h5>Call Us</h5>
                      <p>
                        <a href="tel:+91 7678014775 ">+91 7678014775</a>
                      </p>
                      <p>
                        <a href="tel:+91 90049 39714">+91 90049 39714</a>
                      </p>
                      
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-icon">
                      <i>
                    <IoMdMail className="fa fa-clock" />
                    </i>
                    </div>
                    <div className="contact-info-content">
                      <h5>Email Us</h5>
                      <p>
                        <a href="mailto:shreebalajihospitality2703@gmail.com">
                        shreebalajihospitality2703@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-icon">
                      <i>
                    <FaRegClock className="fa fa-clock" />
                    </i>
                    </div>
                    <div className="contact-info-content">
                      <h5>Open Time</h5>
                      <p>Mon - Sun (09:00AM - 10:00PM)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 align-self-center">
                <div className="contact-map">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3768.657754906519!2d72.84325507520758!3d19.16645278205686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDA5JzU5LjIiTiA3MsKwNTAnNDUuMCJF!5e0!3m2!1sen!2sin!4v1694517625977!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe> */}
                <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60243.38369501032!2d72.80007894245604!3d19.316627381131443!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b1b315c60d77%3A0x296ca337872c347b!2sBalaji%20Complex!5e0!3m2!1sen!2sin!4v1718799853197!5m2!1sen!2sin  "
  // width={600}
  // height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="continer" style={{display:"none"}}>
        <p>Balaji Banquet is a premier venue located in Bhayander, renowned for hosting a wide array of events ranging from weddings to corporate gatherings. As one of the **Top Banquets in Bhayandar**, the Balaji Banquet offers an exceptional blend of luxury and convenience, making it a preferred choice for many. 
        </p>
        <p>
        The hall boasts a spacious and elegantly designed interior that can accommodate a large number of guests, ensuring a comfortable and memorable experience for all. With state-of-the-art facilities and modern amenities, Balaji Banquet is well-equipped to handle events of any scale and style. The venue's professional staff are dedicated to providing impeccable service, ensuring that every detail is meticulously taken care of, from decorations to catering.
        </p>
        <p>
        One of the standout features of Balaji Banquet is its versatile space, which can be customized to suit the specific needs of different events. Whether it's a traditional wedding, a contemporary reception, or a formal corporate event, the venue's adaptable settings ensure that each event is unique and perfectly tailored to the client's vision. This flexibility, combined with the venue's commitment to excellence, firmly positions Balaji Banquet among the **Top Banquets in Bhayandar**.
        </p>
        <p>
        Furthermore, Balaji Banquet is conveniently located in Bhayander, making it easily accessible for guests traveling from various parts of the city. The ample parking space and well-connected transport options add to the convenience, making it a hassle-free experience for all attendees.
        </p>
        <p>
        In addition to its physical amenities, Balaji Banquet also offers a range of value-added services such as event planning assistance, audiovisual equipment, and themed decorations, enhancing the overall event experience. These comprehensive offerings make Balaji Banquet a standout choice for those seeking the **Top Banquets in Bhayandar**.
        </p>
        <p>
          
In summary, Balaji Banquet in Bhayander exemplifies top-tier service and elegant venue design, making it a leading choice for those looking to host memorable events. Its strategic location, versatile space, and exceptional service make it one of the **Top Banquets in Bhayandar**.

        </p>
        </div> 
      <Footer />
    </>
  );
};

export default Contact;
