import React, { useState, useCallback,useLayoutEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import  ProjectImages from "../Components/ServicesData";
import Footer from "../Components/Footer";
import "../CSS/Gallery.css";
import MetaTags from 'react-meta-tags';

const Gallerys = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
    // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    "https://www.pingpongmoments.in/blog/wp-content/uploads/2022/09/corporate-events-3.jpg"
];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };



  ////end
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  return (
    <>
    
      {/* ========= Gallery Banner ====== */}
      <div className="GalleryBanner">
        <div className="container">
          <h1 className="GalleryBanner-title">Banquet Hall For Social Events in Bhayandar</h1>
        </div>
      </div>
      <section className="smile-section">
        <div className="container">
             <div className="sec-title centered">
      <h2 className="text-center my-5">Photo Gallery</h2>
    </div>
           <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
      {images.map((image, index) => (
        <div className="col mb-md-5 mb-3" key={index}>
           {/* <Bounce> */}
          <div className="smile-img" onClick={() => openLightbox(index)}>
            <img src={image} alt="resort gallery" />
            <div className="smile-icon">
              <TiEyeOutline />
            </div>
          </div>
          {/* </Bounce> */}
        </div>
      ))}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
        </div>
      </section> 
      <p className="d-none">
      Welcome to our Gallery of banquet hall for social events in Bhayandar, a visual journey through the enchanting spaces and memorable moments hosted at Shree Balaji banquet hall for social events in Bhayandar. Explore the elegance and versatility of our venue as captured through captivating images and glimpses of events that have graced our halls.

Immerse yourself in the grandeur of our banquet hall through the lens of celebrations—weddings, engagements, corporate events, and more. Witness the meticulous attention to detail in our decor, the seamless execution of events, and the joy radiating from each gathering.

Our Gallery showcases the unique ambiance of the banquet hall for social events in Bhayandar, offering a preview of the stunning backdrops, luxurious interiors, and the vibrant energy that defines our space. From intimate affairs to grand soirées, each image reflects the commitment to excellence that sets us apart.

As you peruse our Gallery, envision your own special moments taking center stage in our distinguished venue. We invite you to discover the beauty, sophistication, and timeless allure that banquet hall for social events in Bhayandar brings to every event. Join us on this visual odyssey and envision your celebration unfolding in the captivating surroundings of our exquisite banquet hall.

      </p>
      {/* =========== Gallery Section ===== */}
      {/* <section className="Gallery-area py-5">
        <div className="container">
          <Gallery photos={ProjectImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={ProjectImages.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </section> */}
      {/* <section>
      <div className="container">
          
             
          <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
     {images.map((image, index) => (
       <div className="col mb-md-5 mb-3" key={index}>
         
         <div className="smile-img" onClick={() => openLightbox(index)}>
           <img src={image} alt="resort gallery" />
           <div className="smile-icon">
             <TiEyeOutline />
           </div>
         </div>
       
       </div>
     ))}
     {lightboxOpen && (
       <Lightbox
         mainSrc={images[photoIndex]}
         nextSrc={images[(photoIndex + 1) % images.length]}
         prevSrc={images[(photoIndex + images.length - 1) % images.length]}
         onCloseRequest={closeLightbox}
         onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
         onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
       />
     )}
   </div>
       </div>
      </section> */}
<div className="container" style={{textAlign:"justify",display:"none"}}>
  <p>
  Welcome to our stunning gallery showcasing the elegance and versatility of our Banquet Hall in Bhayandar. Nestled in the heart of Bhayandar, our venue is designed to host memorable events that exceed expectations.
  
  </p>
  <p>
  Our gallery captures the essence of luxury and sophistication that defines our Banquet Hall in Bhayandar. From intimate gatherings to grand celebrations, each image tells a story of joyous occasions and unforgettable moments. Whether it’s a wedding reception, corporate event, or any special occasion, our hall offers the perfect ambiance and amenities to make every event exceptional.
  </p>
  <p>
  The spacious interiors of our Banquet Hall in Bhayandar are tastefully decorated, providing a blank canvas for personalized décor themes. With flexible seating arrangements and state-of-the-art facilities, we ensure that every detail is meticulously arranged to create a seamless experience for you and your guests.
  </p>
  <p>
  Our gallery also highlights the exquisite catering options available at our Banquet Hall in Bhayandar. From delectable multi-cuisine menus to custom-designed culinary experiences, our chefs craft dishes that delight the senses and complement any event perfectly.
  </p>
  <p>
  The surrounding landscapes and architecture of Bhayandar add an extra charm to our venue, offering picturesque backdrops for outdoor ceremonies or photo opportunities.
  </p>
  <p>
  At balaji banquet, we pride ourselves on delivering exceptional service and creating memories that last a lifetime. Browse through our gallery to envision your dream event at our Banquet Hall in Bhayandar, where every detail is curated to perfection.
  </p>
  <p>
  Contact us today to schedule a visit and let us turn your vision into reality at the most sought-after Banquet Hall in Bhayandar. We look forward to hosting you and making your event truly unforgettable.
  </p>
</div>
      <Footer />
    </>
  );
};

export default Gallerys;
