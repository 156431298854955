import i73 from "../media/images/i73.jpeg"
import i52 from "../media/images/I52.jpeg"
import i74 from "../media/images/I74.avif"

const ServicesData = [
  {
    Header: "Banquet",
    Slugs: "/top-banquet-near-bhayandar",
    description:"Elevate your events at the Top Banquet near Bhayandar. Experience unparalleled elegance and hospitality as we ensure your celebrations become extraordinary.",
    title:"Top Banquet near Bhayandar | Shree Balaji Banquet ",
    keyword:"Top Banquet near Bhayandar",
    ServicesImage:i52,
    SercicesHeading1:"Banquent",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to the top banquet near Bhayandar West, where events are transformed into treasured memories and parties are elevated to new heights. Our dining space is a monument to luxury, style, and flawless service. Nestled in an elegant setting, we are committed to providing a flexible and magnificent venue for a variety of occasions.At the top banquet near Bhayandar West, we understand the significance of your events, whether they are special occasions, corporate gatherings, marriage proposals, or other celebrations. Our dedication to excellence is reflected in the meticulous attention to detail and flawless execution of every event. Our venue's cutting-edge amenities and stunning decor are designed to meet the specific requirements of your special day.",
    SercicesPara2:"Our skilled and knowledgeable staff takes great pride in ensuring that every event at the top banquet near Bhayandar West exceeds guests' expectations. We create an environment where festivities come to life by offering a tasteful blend of comfort, style, and attentive service.Choose the top banquet near Bhayandar West if you're looking for a location that goes above and beyond to make your vision a reality. Create memories that will last a lifetime in the enchanting embrace of the top banquet near Bhayandar West, where each occasion is a masterpiece.",
    headingPoint1:"Customized Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Ample Parking:",
    headingPoint5:"Experienced Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"We offer customizable decoration options to match your baby shower theme, ensuring a visually stunning and Instagram-worthy backdrop for your celebration.",
    ServicesPoint2:"Our in-house catering team provides a wide array of culinary delights, from traditional favorites to modern twists, all prepared with the freshest ingredients to delight your taste buds.",
    ServicesPoint3:"We offer advanced audio-visual equipment to enhance your event, whether it's for showcasing cherished memories or adding a musical touch to your celebration.",
    ServicesPoint4:"We understand the importance of convenience, which is why we provide ample parking space to accommodate your guests comfortably.",
    ServicesPoint5:"Our professional and courteous staff is dedicated to ensuring that every detail of your baby shower is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint6:"Our venue is wheelchair accessible, ensuring that all your guests can comfortably join in the celebration.",
  },
  {
    Header: "Home Stay",
    Slugs: "/banquet-hall-homestay-bhayandar",
    description:"Experience the perfect combination of celebration & comfort with our Banquet Hall With  Homestay in Bhayandar. Host memorable events and enjoy a cozy stay.",
    title:"Banquet Hall with Homestay in Bhayandar  | Shree Balaji Banquet  ",
    keyword:"Banquet Hall with Homestay in Bhayandar",
    ServicesImage:i74,
    SercicesHeading1:"Home Stay",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our Banquet with Homestay in Bhayandar West, a unique location where the opulence of our banquet hall setting is combined with the coziness of home. We reinvent hospitality at the Banquet with Homestay in Bhayandar West by providing a unique home-away-from-home experience in our exquisite setting. Our tastefully furnished rooms offer a comfortable haven, blending contemporary conveniences with a hint of elegance. Located in the center of Bhayandar West, our homestay accommodations serve a range of guests, including business and event-goers. Our Banquet with Homestay in Bhayandar West is ideal for any type of celebration, whether you're looking for a nice place to stay in the city or a place to host your own. Your stay will be both comfortable and unforgettable, thanks to our dedication to providing outstanding service. From individual attention to well-considered details, we work hard to create a welcoming and warm atmosphere. Savour the ease of staying on-site, where your accommodation experience perfectly blends with the beauty of our banquet hall. Select our Banquet with Homestay in Bhayandar West for a stay that skillfully blends the refinement of our venue with the coziness of home. In the heart of Bhayandar West, where every visit is an invitation to enjoy the ideal fusion of sophistication and hospitality, join us in making enduring memories",
    // SercicesPara2:"Our commitment to exceptional service ensures that your stay is not only comfortable but also memorable. From personalized attention to thoughtful details, we strive to create an atmosphere that resonates with warmth and hospitality. Enjoy the convenience of staying on-site, where the elegance of our banquet hall seamlessly extends into your lodging experience.Choose our banquet hall with home stay in Bhayandar for a stay that harmoniously combines the comforts of home with the sophistication of our venue. Join us in creating lasting memories in the heart of Bhayandar, where every visit is an invitation to experience the perfect blend of hospitality and refinement.",
    headingPoint1:"Creative Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that align with your birthday theme, making every detail count.",
    ServicesPoint2:"Our Both branch offers a diverse menu prepared by our culinary experts, catering to a variety of tastes and preferences.",
    ServicesPoint3:"Cutting-edge audio-visual equipment is available for entertainment, presentations, and music, enhancing the overall experience.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:"Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, so you can focus on enjoying your celebration.",
    ServicesPoint6:"Just like our Borivali branch, our Goregaon branch is wheelchair accessible, ensuring inclusivity.",
  },
  {
    Header: "Catering",
    Slugs: "/banquet-halls-catering-bhayandar",
    description:"Discover the finest Banquet Halls with Catering Services in Bhayandar. Elevate your celebrations with our exceptional venues, perfect for weddings.",
    title:"Banquet Halls with Catering in Bhayandar | Shree Balaji Banquet  ",
    keyword:"Banquet Halls with Catering in Bhayandar",
    ServicesImage:i73,
    SercicesHeading1:"Catering",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our Bhayandar event hall with catering, where fine dining meets top-notch service. As a leading catering business, we take immense pride in creating unique dining experiences for a variety of occasions. Our careful selection of top-notch, freshly sourced ingredients and our talented chefs' unwavering dedication to creating mouthwatering dishes are testaments to our commitment to perfection. Whether you're hosting a wedding, business meeting, or special celebration, we at Banquet Halls With Catering In Bhayandar West understand the significance of your event. Our extensive menu selections satisfy a wide range of tastes and discriminating palates with a fusion of flavours. A meal should be more than just served; rather, it should be a culinary adventure that gives your event a unique flair.",
    SercicesPara2:"From menu planning to presentation, our skilled and accommodating crew ensures that every aspect of your catering experience is carried out flawlessly. Our top priority is adaptability, enabling customization to meet the particular needs of any occasion. Whether it's a small gathering or a large celebration, you can count on Banquet Halls With Catering In Bhayandar West to make it unforgettable with delicious food and first-rate service. Select our Bhayandar banquet space with catering for an unforgettable culinary experience that will transform your occasion into a gastronomic feast. Come enjoy the culinary craft of Banquet Halls With Catering In Bhayandar West, where each dish reveals a tale of unmatched flavour, attention to detail, and love.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Experienced Staff:",
    headingPoint4:"Audio-Visual Setup:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that capture the essence of your engagement, creating a magical ambiance.",
    ServicesPoint2:"Our in-house culinary experts prepare a delectable range of dishes, from traditional favorites to modern delicacies, ensuring that your guests are treated to a memorable dining experience.",
    ServicesPoint3:"Our professional staff in Borivali is committed to ensuring that every detail of your engagement is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint4:"We offer state-of-the-art audio-visual equipment to enhance your celebration, including sound systems and projectors for speeches and presentations.",
    ServicesPoint5:"Convenient parking facilities are available to accommodate your guests comfortably.",
    ServicesPoint6:"We prioritize air conditioners, exhaustion fans, dais/stage, lighting systems, sound & music systems, fire extinguishers, food serving, dining facilities, welcome carpets, and private cabins.",
  },
  // {
  //   Header: "NAMING CEREMONY",
  //   Slugs: "/naming-ceremony",
  //   ServicesImage:Gallery7,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"At lorem ipsum Hall, we take immense pride in providing couples with an exquisite and unforgettable venue for their marriage celebrations. With a commitment to excellence and a keen understanding of the profound significance of this momentous day, we offer a truly exceptional experience that transcends the ordinary.",
  //   SercicesPara2:"Our banquet halls are thoughtfully designed, exuding an air of sophistication and opulence. Whether you envision an intimate and romantic ceremony or a grand, extravagant affair, our versatile spaces can be tailored to your specific needs and preferences. lorem ipsum Hall serves as a canvas where your dreams and love story come to life, where every detail is meticulously curated to ensure your marriage celebration is nothing short of magical.",
  //   headingPoint1:"Bespoke Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Dedicated and Attentive Staff:",
  //   headingPoint6:"Inclusivity:",
  //   ServicesPoint1:"Our talented decorators work closely with you to create a visually stunning atmosphere that embodies the essence of your love story.",
  //   ServicesPoint2:"Our in-house culinary team comprises skilled chefs who are passionate about creating a culinary journey that resonates with your palate and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from top-tier sound systems for crystal-clear vows to dazzling visual displays that enhance your reception's ambiance.",
  //   ServicesPoint4:"Our seasoned wedding coordinators are not just professionals; they are partners in ensuring that every element of your special day unfolds seamlessly, allowing you to fully immerse yourself in the celebration.",
  //   ServicesPoint5:"Our passionate and courteous staff is dedicated to ensuring that your marriage celebration is executed flawlessly, from the initial setup to the final moments of your event.",
  //   ServicesPoint6:"We prioritize inclusivity by offering wheelchair accessibility, ensuring that all your loved ones can participate fully in the joy of your union.",
  // },
  // {
  //   Header: "BABY SHOWER",
  //   Slugs: "/baby-shower",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a Shree Balaji, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "MEHANDI",
  //   Slugs: "/mehandi",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a Shree Balaji, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "SANGEET",
  //   Slugs: "/sangeet",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a Shree Balaji, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "CORPORATE EVENTS",
  //   Slugs: "/corporate-event",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a Shree Balaji, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "STAY FOR GUEST",
  //   Slugs: "/stay-for-guest",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a Shree Balaji, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
];

export default ServicesData;
