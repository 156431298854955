import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';

import Services from './Pages/Services';
import Gallerys from './Pages/Gallerys';
import ServicesData from './Components/ServicesData';
import EventsData from "../src/Components/EventsData";
import Events from "./Pages/Events";
// import Speciality from './Pages/Speciality';
// import SpecialityData from './Components/SpecialityData';
import FloatingIcon from './Pages/FloatingIcon';
import ErrorPage from './Pages/ErrorPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <FloatingIcon />
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/best-banquet-bhayandar' element={<About />} />
          <Route path='/best-banquet-near-bhayandar' element={<Contact />} />
          {/* <Route path='/Story' element={<Story />} />
          <Route path='/Services' element={<Services />} /> */}
          <Route path='/banquet-hall-social-events-bhayandar' element={<Gallerys />} />
          {ServicesData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<Services/>}
            />
          ))}
          {EventsData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<Events/>}
            />
          ))}
          <Route path='/*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;