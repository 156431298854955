import React from "react";
import "../CSS/About.css";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FaFaceSmile } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import { IoIosCall } from "react-icons/io";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import Footer from "../Components/Footer";
import MetaTags from 'react-meta-tags';
import i6 from "../media/images/i6.jpeg"
import i62 from "../media/images/I62.jpeg"
import i1 from "../media/images/i1.jpeg"
import i2 from "../media/images/i2.jpeg"
import i21 from "../media/images/I21.jpeg"
import i52 from "../media/images/I52.jpeg"
import I50 from "../media/images/I50.jpeg"
import I43 from "../media/images/I43.jpeg"
import I39 from "../media/images/I39.jpeg"
import I35 from "../media/images/I35.jpeg"
import I71 from "../media/images/i71.webp"
import i72 from "../media/images/i72.jpeg"
import i73 from "../media/images/i73.jpeg"
import I52 from "../media/images/I52.jpeg"
import i74 from "../media/images/I74.avif"
import i37 from "../media/images/I37.jpeg"
// =========== Insta Img ======
const About = () => {
  return (
    <>
      {/* ========== About Banner ========= */}
      
      <div className="AboutBanner">
        <div className="container">
          <h1 className="AboutBanner-title">
          Best Banquet in Bhayandar
          </h1>
        </div>
      </div>

      {/* ========== About section ============= */}
      <section className="about-area py-5 mt-5 mb-5">
        
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="about-left wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInLeft",
                }}
              >
                <div className="about-img">
                  <img src={i62} alt="img" />
                </div>
                <div className="about-experience">
                  <h2>
                    10 <span>+</span>
                  </h2>
                  <span className="about-experience-text">
                    Years Of Experience
                  </span>
                </div>
                <div className="about-img-shape">
                  <img
                    src="https://live.themewild.com/lovex/assets/img/shape/01.svg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-right wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h2 className="site-title">
                    Elevate Your Celebrations <span>Moment with</span> Shree Balaji Party Hall!
                  </h2>
                </div>
                <p className="about-text">
                The presenting Party Banquet in Bhayandar is the Shree Balaji Banquet it's a prime location with an AC hall that can accommodate 75–250 people. It's ideal for discriminating folks looking for unmatched elegance and flawless event execution. With a tradition of excellence, our banquet hall stands as a symbol of sophistication and luxury. We provide a sophisticated environment for social gatherings, business functions, and weddings, complete with exquisite furnishings and amenities. Our skilled staff is committed to going above and beyond, making sure every event is carried out with style and accuracy. Find the greatest banquet in Bhayandar, where perfection meets professionalism.
                </p>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>Premier location, AC hall accommodating 75–250 persons.</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>
                        Interiors with exquisite design and first-rate facilities.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>
                        A skilled staff guarantees accuracy and style in performance.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="about-bottom">
                  <Link to='/best-banquet-near-Bhayandar'>
                  <a href="/best-banquet-near-Bhayandar" className="theme-btn">
                    Read More <i class="fa-solid fa-arrow-right"></i>
                  </a>
                  </Link>
                  <div className="about-call">
                    <div className="about-call-icon">
                      <i className="fa fa-phone" />
                      <IoIosCall/>
                      {/* <i className="fa fa-user-headset" /> */}
                    </div>
                    <div className="about-call-content">
                      <span>Call Us Anytime</span>
                      <h5 className="about-call-number">
                        <a href="tel:+91 7678014775 ">+91 7678014775
</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= About Counter ========= */}
      <section className="counter-area mt-0 pb-5">
        <div className="container">
          <div className="counter-wrapper">
            <div className="row row-cols-lg-4 row-cols-2">
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-suitcase" /> */}
                    <i className="fa-regular fa-calendar-days"><FaRegCalendarAlt/></i>
                    

                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={10} duration={5} />
                    </span>
                    <h6 className="title">+ Years Of Exprience </h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-wedding-arch" /> */}
                    <i className="fa-regular fa-calendar-days"><IoPerson/></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={1200} duration={5} />
                    </span>
                    <h6 className="title">+ Number Of Client</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-pastor" /> */}
                    <i className="fa-regular fa-calendar-days"><FaFaceSmile/></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={1000} duration={5} />
                    </span>
                    <h6 className="title">+ Happy Couples</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-placeholder" /> */}
                    <i className="fa-regular fa-calendar-days"><FaStar/></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={500} duration={5} />
                    </span>
                    <h6 className="title">+ Person Review</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== About Cta ========== */}
      <section className="cta-area pb-5">
        <div className="container">
          <div className="cta-wrapper">
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <div className="cta-text">
                  <h2>We Are Ready To Celebrate Your All Types Of Event</h2>
                  <p>
                  With meticulous attention to detail and a commitment to excellence, we create tailored experiences that reflect the uniqueness of each event.
                  </p>
                </div>
                <a
                  href="tel:+917678014775"
                  className="theme-btn mt-30"
                  target="blank"
                >
                  Contact Now <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== About Testimonal ========= */}
      <section className="testimonial-area bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Testimonials</span>
                <h2 className="site-title">
                  What Our Client <span>Say's</span>
                </h2>
                {/* <div className="header-img-divider">
                  <img
                    src="https://live.themewild.com/lovex/assets/img/icon/divider.png"
                    alt="Engagement hall in Goregaon West"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="homeTestimoial"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Ajanta Party Hall Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/01.jpg"
                        alt="Borivali West kitty party venue"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4>vipul pai</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Pleasant stay. Spacious and clean rooms. Mr. Nitin  really helped us customise our stay duration according to our needs and was cordial. highly recommend this property for your next stay.
                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Birthday party hall in Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/02.jpg"
                        alt="Conference party venue Borivali West"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Gauresh Mahadik</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Excellent experience! Management was top class, easy access to the hall plus accommodation was of elite level! Would recommend everyone.

                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Marriage hall in Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/03.jpg"
                        alt="Borivali West thread ceremony venue"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Raju Mal</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Good Experience with Shree Balaji hall 👌
                    Overall the management Team is politely & Familiar
                    Thank For Wonderful Experience
                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Reception hall Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/04.jpg"
                        alt="Engagement hall in Borivali West"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4> babu rao </h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Great staff Clean rooms are available here.
                  1. Ambiance :- Excellent.
                  2. Decoration Stage :- Excellent
                  3. AC condition :- Excellent
                  4. Chairs & other Facilities :- Excellent.

                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* ======== About Instra ======== */}
      <div className="instagram-area">
        <div className="container-fluid px-0">
        <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              570: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              990: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
            }}
            modules={[Autoplay]}
            className="homeInstagram"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={i2} alt="  Hall in Bhayandar "/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I39} alt="Party  in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={i21} alt="Party Hall "/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I35} alt="Party Hall  Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={i52} alt="Party  in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I50} alt="Party in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I43} alt=" Hall in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
