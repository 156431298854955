import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdFoodBank } from "react-icons/md";
import { FaCarSide } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import MetaTags from 'react-meta-tags';
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
// import about from "../Img/about.jpg";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import "../CSS/Home.css";
import Footer from "../Components/Footer";

import i1 from "../media/images/i1.jpeg"
import i2 from "../media/images/i2.jpeg"
import i21 from "../media/images/I21.jpeg"
import i52 from "../media/images/I52.jpeg"
import I50 from "../media/images/I50.jpeg"
import I43 from "../media/images/I43.jpeg"
import I39 from "../media/images/I39.jpeg"
import I35 from "../media/images/I35.jpeg"
import I71 from "../media/images/i71.webp"
import i72 from "../media/images/i72.jpeg"
import i73 from "../media/images/i73.jpeg"
import I52 from "../media/images/I52.jpeg"
import i74 from "../media/images/I74.avif"
import i37 from "../media/images/I37.jpeg"

// =========== Insta Img ======


import $ from "jquery";
import ServicesData from "../Components/ServicesData";
import EventsData from "../Components/EventsData";

const Home = (props) => {

  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custContact || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">SHREE BALAJI Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at SHREE BALAJI</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "shreebalajihospitality2703@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "SHREEBALAJI",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");
   };
  return (
    <>
   
      <div className="hero-section">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <div className="homebanner1">
              <div className="hero-single">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <div className="hero-content align-items-center">
                        <h6>We Are Skilled &amp; Expert</h6>
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".50s"
                          style={{ animationDelay: "0.5s" }}
                        >
                        Banquet Hall
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          className=""
                          style={{ animationDelay: "0.75s" }}
                        >
                        Welcome to our party hall in Bhayandar, where moments become memories. We pride ourselves on creating elegant and unforgettable experiences for your special events. From weddings to corporate gatherings, trust us to turn your vision into reality at our exceptional venue.
                        </p>
                        <div
                          className="hero-btn"
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          style={{ animationDelay: "0.75s" }}
                        >
                          <Link to="/best-banquet-bhayandar" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          {/* <a href="/About" className="theme-btn">
                            About More
                            <i class="fa-solid fa-arrow-right"></i>
                          </a> */}
                            <Link to="/best-banquet-near-bhayandar" className="theme-btn theme-btn2">Learn More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          {/* <a href="/kitty-party" className="theme-btn theme-btn2">
                            Learn More
                            <i class="fa-solid fa-arrow-right"></i>
                          </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homebanner2">
              <div className="hero-single">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <div className="hero-content align-items-center">
                        <h6>We Are Skilled &amp; Expert</h6>
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".50s"
                          style={{ animationDelay: "0.5s" }}
                        >
                        Food Dining Area 
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          className=""
                          style={{ animationDelay: "0.75s" }}
                        >
                        Our food dining area is a gastronomic haven where culinary excellence meets exquisite ambiance. From intimate gatherings to grand celebrations, our carefully curated menu showcases a fusion of flavors prepared with precision and creativity. Our culinary artisans are committed to crafting memorable dining moments, ensuring each dish is a masterpiece.
                        </p>
                        <div
                          className="hero-btn"
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          style={{ animationDelay: "0.75s" }}
                        >
                          <Link to="/best-banquet-bhayandar" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          <Link to="/best-banquet-near-bhayandar" className="theme-btn theme-btn2">Learn More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homebanner3">
              <div className="hero-single">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <div className="hero-content align-items-center">
                        <h6>We Are Skilled &amp; Expert</h6>
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".50s"
                          style={{ animationDelay: "0.5s" }}
                        >
                          Home Stay
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          className=""
                          style={{ animationDelay: "0.75s" }}
                        >
                        Welcome to our party hall in Bhayandar, where we redefine the concept of home stays within the grandeur of our banquet hall setting. Nestled in luxury, our home stay accommodations offer a blend of comfort and sophistication. Whether you're here for a special event or a leisure retreat, our well-appointed rooms provide a welcoming sanctuary.
                        </p>
                        <div
                          className="hero-btn"
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          style={{ animationDelay: "0.75s" }}
                        >
                          <Link to="/best-banquet-bhayandar" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          <Link to="/best-banquet-near-bhayandar" className="theme-btn theme-btn2">Learn More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* ========== Home About ============= */}
      <section className="about-area py-5 mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="about-left wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInLeft",
                }}
              >
                <div className="about-img">
                  <img
                    src={i1}
                    alt="Party Hall in Bhayandar "
                  />
                </div>
                <div className="about-experience">
                  <h2>
                    10 <span>+</span>
                  </h2>
                  <span className="about-experience-text">
                    Years Of Experience
                  </span>
                </div>
                <div className="about-img-shape">
                  <img
                    src={i2}
                    alt="Party Hall"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-right wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h1 className="site-title">
                  <span> Party Hall in Bhayandar </span>
                  </h1>
                </div>
                <p className="about-text">
                Featuring meticulously designed interiors and top-notch amenities, our banquet hall offers a refined setting ideal for weddings, corporate events, and social gatherings. Our professional team is dedicated to exceeding expectations, ensuring that every event is executed with precision and flair. Discover where professionalism meets perfection at the Shree Balaji Banquet in Bhayander.
                </p>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                      <p>Premier venue with an AC hall for 75–250 people.</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>Meticulously designed interiors and top-notch amenities.</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                      <p>Professional team, ensuring precision and flair in execution.</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="about-bottom">
                <Link to="/best-banquet-bhayandar" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                  <div className="about-call">
                    <div className="about-call-icon">
                    <i className="fa fa-phone" />
                    <IoIosCall/>
                    </div>
                    <div className="about-call-content">
                      <span>Call Us Anytime</span>
                      <h5 className="about-call-number">
                        <a href="tel:+919987220795">+91 7678014775 </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Home Services ============ */}
      <section className="service-area bg py-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay=".25s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.25s",
                animationName: "fadeInDown",
              }}
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Our Events</span>
                <h2 className="site-title">
                  Our Awesome Events
                </h2>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
            modules={[ Pagination]}
            className="homeService"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                    <img src={i52} alt=" Hall in Bhayandar "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#" style={{textDecoration:"none"}}>Wedding</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Discover the elegance and convenience of the best banquet for weddings in Bhayandar, where your special day is crafted to perfection. Our premier banquet hall offers a stunning setting, exceptional service, and a variety of customizable options to suit any theme or size of celebration. With state-of-the-art facilities, exquisite decor, and a dedicated team of professionals, we ensure that every detail is meticulously planned and executed, making your wedding an unforgettable event. Experience the finest hospitality and celebrate your love story at the most sought-after banquet hall in Bhayandar.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={i21} alt="Party Hall in Bhayandar"/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Engagement</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Located in the bustling suburb of Bhayandar, our exquisite venue offers the perfect Banquet For Engagement in Bhayandar, setting the stage for unforgettable celebrations. As the premier banquet hall in Bhayandar, we pride ourselves on providing top-notch amenities, elegant decor, and personalized services tailored to your every need. Whether you envision a grand affair or an intimate gathering, our dedicated event planning team ensures every detail is meticulously crafted to create a memorable experience for you and your guests. With spacious interiors, state-of-the-art facilities, and a reputation for excellence, our Banquet For Engagement in Bhayandar is the ideal venue to mark the beginning of your beautiful journey together.

                    </p>
                    
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={I43} alt="Party Hall "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Birthday Party</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Our Banquet Hall for Birthday in Bhayandar is the ideal choice for hosting unforgettable celebrations. With elegant décor, customizable seating arrangements, and state-of-the-art amenities, our hall ensures a seamless and enjoyable experience for you and your guests. Our dedicated staff takes care of every detail, from catering delicious cuisines to arranging entertainment, allowing you to focus on making memories. Conveniently located in Bhayandar, our banquet hall is easily accessible, making it a popular choice for residents looking to celebrate in style.
                    </p>
                    
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={I39} alt="Party Hall in Bhayandar"/>
                  </div>
                 
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Naming Ceremony</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Located in the heart of Bhayandar, our banquet hall is renowned for hosting memorable naming ceremonies with unmatched elegance and seamless service. As the premier banquet hall in Bhayandar for naming ceremonies, we pride ourselves on creating a warm and inviting atmosphere where every detail is meticulously planned to ensure your event is unforgettable. Whether you're celebrating a joyous occasion or a significant milestone, our banquet hall offers a versatile space that can be tailored to suit your specific needs, promising an experience that exceeds expectations. Discover the epitome of hospitality at our banquet hall in Bhayandar for naming ceremonies, where cherished moments are crafted with care and finesse.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={I50} alt="Party Hall "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Baby Shower</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Located at our Banquet in Bhayandar, we provide a picturesque venue perfect for celebrating special occasions like baby shower ceremonies. Surrounded by serene landscapes, our banquet hall blends elegant decor with flawless service to ensure every aspect of your event is flawless. Whether your vision is traditional or modern, our devoted team is dedicated to crafting unforgettable experiences that surpass your expectations. Offering customizable setups and delightful catering choices, we aim to create a cherished and joyful baby shower ceremony at our Banquet in Bhayandar.

                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={i72} alt=" Party Hall "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Mehandi</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Discover our exquisite banquet hall nestled in Bhayandar, offering a luxurious venue perfect for hosting unforgettable mehndi ceremonies. Located in the vibrant heart of Bhayandar, our hall blends elegant décor with modern amenities, creating an ideal setting for celebrations. Whether you prefer a traditional affair or a contemporary gathering, our versatile space and dedicated staff ensure every detail is tailored to perfection. Experience the pinnacle of hospitality and charm at our mehendi venue in Bhayandar, where each moment is crafted into a cherished memory.


                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
              <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={I71} alt=" Hall in Bhayandar"/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Sangeet</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Top Banquet for Sangeet In Bhayandar Discover our renowned banquet hall in Bhayandar, where elegance meets functionality to create the perfect setting for your sangeet ceremony. Our venue is synonymous with exquisite events, meticulously planned and executed to perfection. From decor to catering and entertainment, we cater to every detail, ensuring an unforgettable experience for you and your guests.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={I35} alt="Party in Bhayandar "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Corporate events</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Banquet For Corporate Events in Bhayandar is strategically situated at the heart of Bhayandar. Our venue stands out as the premier choice for corporate events, seamlessly blending elegance with practicality. Whether you're organizing a business conference, seminar, or team-building retreat, our state-of-the-art facilities and dedicated event planners ensure a flawless experience from beginning to end. Offering customizable spaces, modern amenities, and exquisite catering options, our banquet hall is meticulously designed to surpass your expectations and create unforgettable moments for you and your guests. Experience unparalleled service and sophistication for your next corporate event with us


                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            
          </Swiper>
        </div>
      </section>
      <div className="gallery-area py-5">
        
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto"
              data-wow-duration="1s"
              data-wow-delay=".25s"
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Our Services</span>
                <h2 className="site-title">
                Discover what sets us apart
                </h2>
              </div>
            </div>
          </div>
          <div
            className="row popup-gallery"
            style={{
              position: "relative",
            }}
          >
           
            <div className="col-md-7">
            <div className="gallery-item1">
  <div className="gallery-img1">
    <div className="overlay">
    <Link to='/banquet-hall-social-events-Bhayandar'>
    <p className="overlay-title">Catering Services</p>
    </Link>
      <p className="overlay-subtitle">Exquisite culinary experiences for your events</p>
    </div>
    <img
      src={i73}
      alt="Party  in Bhayandar "
    />
  </div>
</div>
            </div>
            <div className="col-md-5">
              <div className="row">
              <div className="col-12">
  <div className="gallery-item">
    <div className="gallery-img">
      <div className="overlay">
      <Link to='/banquet-hall-social-events-Bhayandar'>
      <p className="overlay-title">Banquet Services</p>
      </Link>
        
        <p className="overlay-subtitle">Elegant venues for unforgettable gatherings</p>
      </div>
      <img src={i52} alt="  Hall in Bhayandar" />
    </div>
   
  </div>
</div>

<div className="col-12">
  <div className="gallery-item">
    <div className="gallery-img">
      <div className="overlay">
      <Link to='/banquet-hall-social-events-Bhayandar'>
      <p className="overlay-title">Homestay Services</p>
      </Link>
        <p className="overlay-subtitle">Your home away from home</p>
      </div>
      <img src={i74} alt=" Party Hall " />
    </div>
   
  </div>
</div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
     
      {/* ========= Home counter ========== */}
      <div className="counter-area my-5">
        <div className="container">
          <div className="counter-wrapper">
          <div className="site-heading text-center">
                <span className="site-title-tagline">Our amenities</span>
                <h2 className="site-title">
                Discover the extraordinary features that await you
                </h2>
              </div>
            <div className="row row-cols-lg-4 row-cols-2">
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                  <MdFoodBank />
                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title">Veg Food  </h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    <FaUserAlt />

                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title"> upto 25 people Stay  </h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    <FaCarSide />
                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title"> Parking</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                  <FaPeopleGroup />
                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title">125 Sitting</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========== Home Quote-Area ===== */}
      <section className="quote-area py-5">
        <div className="container">
          <div className="quote-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="quote-img">
                  <img
                    src={i21}
                    alt="Party Hall in Bhayandar"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="quote-content">
                  <div className="quote-header">
                    <h6>Get Free Quote</h6>
                    <h2>Feel Free To Contact With Us</h2>
                  </div>
                  <form
        method="post"
        ref={formRef}
        action=""
        id="contact-form"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name"
                onChange={(e) => setcustName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                onChange={(e) => setCustEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="number"
                name="contact"
                placeholder="Your Contact Number"
                className="form-control"
                onChange={(e) => setCustContact(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <select
                className="form-control"
                name="service"
                onChange={(e) => setCustSubject(e.target.value)}
              >
                <option value="">Choose Service</option>
                {ServicesData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
                {EventsData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <textarea
            name="message"
            cols={30}
            rows={5}
            className="form-control"
            placeholder="Write Your Message"
            onChange={(e) => setCustMessage(e.target.value)}
          />
        </div>
        {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button type="submit" className="theme-btn" onClick={ContactEnquiry}>
          Send Message <i className="far fa-paper-plane" />
        </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Home Cta ========== */}
      <section className="cta-area pb-5">
        <div className="container">
          <div className="cta-wrapper">
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <div className="cta-text">
                  <h2>We Are Ready To Celebrate Your All Types Of Event</h2>
                  <p>
                  With meticulous attention to detail and a commitment to excellence, we create tailored experiences that reflect the uniqueness of each event.
                  </p>
                </div>
                <a href="tel:+919987220795" className="theme-btn mt-30" target="blank">
                  Contact Now <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== Home Testimonal ========= */}
      <section className="testimonial-area bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Testimonials</span>
                <h2 className="site-title">
                  What Our Client Say's 
                </h2>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="homeTestimoial"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                   
                    </div>
                    <div className="testimonial-author-info">
                      <h4>vipul pai</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Pleasant stay. Spacious and clean rooms. Mr. Nitin  really helped us customise our stay duration according to our needs and was cordial. highly recommend this property for your next stay.
                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                   
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                    
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Gauresh Mahadik</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Excellent experience! Management was top class, easy access to the hall plus accommodation was of elite level! Would recommend everyone.

                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                   
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Raju Mal</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Good Experience with Shree Balaji 👌
                    Overall the management Team is politely & Familiar
                    Thank For Wonderful Experience
                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                   
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      
                    </div>
                    <div className="testimonial-author-info">
                      <h4> babu rao </h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Great staff Clean rooms are available here.
                  1. Ambiance :- Excellent.
                  2. Decoration Stage :- Excellent
                  3. AC condition :- Excellent
                  4. Chairs & other Facilities :- Excellent.

                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* ======== Home Instra ======== */}
      <div className="instagram-area">
        <div className="container-fluid px-0">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              570: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              990: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
            }}
            modules={[Autoplay]}
            className="homeInstagram"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={i2} alt="  Hall in Bhayandar "/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I39} alt="Party  in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={i21} alt="Party Hall "/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I35} alt="Party Hall  Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={i52} alt="Party  in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I50} alt="Party in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={I43} alt=" Hall in Bhayandar"/>
                    <a href="https://www.instagram.com/shreebalajiresto.banquet/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
