
import i52 from "../media/images/I52.jpeg"
import i21 from "../media/images/I21.jpeg"
import i43 from "../media/images/I43.jpeg"
import i39 from "../media/images/I39.jpeg"
import i72 from "../media/images/i72.jpeg"
import i71 from "../media/images/i71.webp"
import i35 from "../media/images/I35.jpeg"
const EventsData = [
  {
    Header: "WEDDING",
    Slugs: "/best-party-hall-bhayandar-weddings",
    title:"Best Party Hall in Bhayandar For Weddings | Shree Balaji Banquet ",
    description:"Discover the epitome of elegance and celebration at the Best Party Hall in Bhayandar for weddings. Let your special day unfold in grandeur.",
    keyword:"Best Party Hall in Bhayandar For Weddings",
    ServicesImage:i52,
    SercicesHeading1:"Wedding",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to the Best Banquet for Weddings in Bhayandar, where dreams come true and your most treasured day is immortalized. As a premier wedding location, we take great pride in providing a magical setting where love stories come to life. With its sophisticated decor, our magnificent banquet space is the ideal backdrop for your love celebration. Our skilled staff is committed to creating an environment that reflects your vision, whether it be for small, intimate ceremonies or grand, formal celebrations. Detailed preparation and close attention to detail are the cornerstones of our dedication to making sure your wedding day runs smoothly.",
    SercicesPara2:"Experience the opulent atmosphere of the Best Banquet for Weddings in Bhayandar, where romance and sophistication are felt in every corner. Our knowledgeable team puts in endless effort to make your vision a reality and delivers a day that exceeds expectations. Whether it's a contemporary celebration or a wedding straight out of a fairy tale, we take great delight in turning your moments into treasured memories. Choosing the Best Banquet for Weddings in Bhayandar will provide you with an elegant, cozy, and joyful start to a wonderful adventure together",
    headingPoint1:"Customized Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Ample Parking:",
    headingPoint5:"Experienced Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"We offer customizable decoration options to match your baby shower theme, ensuring a visually stunning and Instagram-worthy backdrop for your celebration.",
    ServicesPoint2:"Our in-house catering team provides a wide array of culinary delights, from traditional favorites to modern twists, all prepared with the freshest ingredients to delight your taste buds.",
    ServicesPoint3:"We offer advanced audio-visual equipment to enhance your event, whether it's for showcasing cherished memories or adding a musical touch to your celebration.",
    ServicesPoint4:"We understand the importance of convenience, which is why we provide ample parking space to accommodate your guests comfortably.",
    ServicesPoint5:"Our professional and courteous staff is dedicated to ensuring that every detail of your baby shower is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint6:"Our venue is wheelchair accessible, ensuring that all your guests can comfortably join in the celebration.",
  },
  {
    Header: "ENGAGEMENT",
    Slugs: "/banquet-engagement-bhayandar",
    title:"Banquet for Engagement in Bhayandar | Shree Balaji Banquet ",
    description:"Celebrate love in style with our exquisite Banquet for Engagement in Bhayandar. Discover a perfect blend of romance & sophistication creating lifetime memories.",
    keyword:"Banquet for Engagement in Bhayandar",
    ServicesImage:i21,
    SercicesHeading1:"Engagement",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to Bhayandar's premier Banquet for Engagement in Bhayandar, where romance blossoms and engagements become enchanting celebrations. Our banquet space, nestled in an elegant setting, is perfect for hosting your special day of commitment. From intimate gatherings to grand celebrations, we take pride in creating an atmosphere that reflects the unique spirit of your love story.",
    SercicesPara2:"Our dedication to excellence at the Banquet for Engagement in Bhayandar ensures that your engagement party is a seamless blend of joy and sophistication. Our experienced team is committed to bringing your vision to life, meticulously planning every detail to create an unforgettable event. With our dedicated staff and outstanding decor, your engagement day can be truly extraordinary.At our Banquet for Engagement in Bhayandar, immerse yourself in a world of romance and celebration, where every moment promises an exquisite journey ahead. Trust us to organize an engagement that perfectly captures the magic of the occasion and heralds a lifetime of love. For an engagement party that elegantly and stylishly tells your unique story, choose our banquet in Bhayandar.",
    headingPoint1:"Creative Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that align with your birthday theme, making every detail count.",
    ServicesPoint2:"Our Both branch offers a diverse menu prepared by our culinary experts, catering to a variety of tastes and preferences.",
    ServicesPoint3:"Cutting-edge audio-visual equipment is available for entertainment, presentations, and music, enhancing the overall experience.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:"Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, so you can focus on enjoying your celebration.",
    ServicesPoint6:"Just like our Borivali branch, our Goregaon branch is wheelchair accessible, ensuring inclusivity.",
  },
  {
    Header: "BIRTHDAY PARTY",
    Slugs: "/bhayandar-birthday-party-hall",
    title:"Birthday Party Hall in Bhayandar | Shree Balaji Banquet ",
    description:"Make every moment magical with our Birthday Party Hall in Bhayandar. Discover a perfect blend of ambiance and services for an unforgettable celebration.",
    keyword:"Birthday Party Hall in Bhayandar",
    ServicesImage:i43,
    SercicesHeading1:"Birthday Party",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our premier Banquet Hall for Birthday in Bhayandar, where we specialize in turning birthday celebrations into unforgettable moments of joy and festivity. Our banquet hall serves as the ideal setting for commemorating milestones, creating lasting memories, and embracing the joy of birthdays. Whether you’re planning a significant milestone event, a surprise bash, or an intimate gathering, our versatile and welcoming space is designed to cater to every occasion.At our Banquet Hall for Birthday in Bhayandar, we are committed to delivering excellence, ensuring your birthday party is a seamless blend of excitement and sophistication. Our dedicated team takes care of every detail, from personalized decor to delectable catering, so you can focus on celebrating with your loved ones. The ambiance of our hall is infused with the spirit of celebration, ensuring that your birthday is a truly special and memorable experience.",
    SercicesPara2:"We pride ourselves on offering a venue that goes beyond the ordinary. Our hall is more than just a space; it’s a place where each moment is thoughtfully crafted with care and flair. Our personalized services are tailored to reflect your style and vision, creating an atmosphere filled with laughter, joy, and the warmth of shared celebrations.Choose our Banquet Hall Birthday in Bhayandar for your next celebration and experience a venue that not only meets but exceeds your expectations. Let us be your host, and together, we’ll create an event that is memorable and unique. Our hall is designed to make your birthday a grand celebration, marked by elegance and joy, leaving you with cherished memories.Join us at our Banquet Hall for a Birthday in Bhayandar, where every birthday becomes a milestone of happiness and shared moments. Celebrate with us and make your birthday an event to remember.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Experienced Staff:",
    headingPoint4:"Audio-Visual Setup:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that capture the essence of your engagement, creating a magical ambiance.",
    ServicesPoint2:"Our in-house culinary experts prepare a delectable range of dishes, from traditional favorites to modern delicacies, ensuring that your guests are treated to a memorable dining experience.",
    ServicesPoint3:"Our professional staff in Borivali is committed to ensuring that every detail of your engagement is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint4:"We offer state-of-the-art audio-visual equipment to enhance your celebration, including sound systems and projectors for speeches and presentations.",
    ServicesPoint5:"Convenient parking facilities are available to accommodate your guests comfortably.",
    ServicesPoint6:"We prioritize air conditioners, exhaustion fans, dais/stage, lighting systems, sound & music systems, fire extinguishers, food serving, dining facilities, welcome carpets, and private cabins.",
  },
  {
    Header: "NAMING CEREMONY",
    Slugs: "/party-hall-bhayandar-naming-ceremony",
    title:"Party Hall in Bhayandar for Naming Ceremony  | Shree Balaji Banquet  ",
    description:"Celebrate the joy of a Naming Ceremony at our sophisticated Party Hall in Bhayandar. Immerse your special day in elegance.",
    keyword:"Party Hall in Bhayandar for Naming Ceremony",
    ServicesImage:i39,
    SercicesHeading1:"Naming Ceremony",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our elegant banquet hall in Bhayandar for naming ceremonies, where the pleasure of fresh starts is celebrated with warmth and grace. Recognizing the importance of presenting your little one to loved ones, our banquet space is the perfect location for your priceless naming ceremony. At Shree Balaji Hall, we're proud to offer an elegant, private setting that captures the happiness and affection of this momentous occasion.",
    SercicesPara2:"Your naming ceremony will effortlessly combine tradition and modern elegance thanks to our dedication to quality. Thoughtful décor adorns the ambience, fostering a welcoming mood for your esteemed guests. Our skilled staff is committed to planning an occasion that recognizes the importance of this celebration. Enjoy the beauty of life's early beginnings at our banquet hall in Bhayandar for naming ceremonies, where every little detail is carefully taken care of so you may concentrate on the happiness of sharing your blessings. You may rely on us to make your naming ceremony an unforgettable occasion that is brimming with affection, chuckles, and the warmth of loved ones. Come celebrate with us at our Bhayandar banquet hall for naming ceremonies as we name your child and make everlasting memories as you welcome them into the world of love and joy.",
    headingPoint1:"Bespoke Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Dedicated and Attentive Staff:",
    headingPoint6:"Inclusivity:",
    ServicesPoint1:"Our talented decorators work closely with you to create a visually stunning atmosphere that embodies the essence of your love story.",
    ServicesPoint2:"Our in-house culinary team comprises skilled chefs who are passionate about creating a culinary journey that resonates with your palate and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from top-tier sound systems for crystal-clear vows to dazzling visual displays that enhance your reception's ambiance.",
    ServicesPoint4:"Our seasoned wedding coordinators are not just professionals; they are partners in ensuring that every element of your special day unfolds seamlessly, allowing you to fully immerse yourself in the celebration.",
    ServicesPoint5:"Our passionate and courteous staff is dedicated to ensuring that your marriage celebration is executed flawlessly, from the initial setup to the final moments of your event.",
    ServicesPoint6:"We prioritize inclusivity by offering wheelchair accessibility, ensuring that all your loved ones can participate fully in the joy of your union.",
  },
 
  {
    Header: "MEHANDI",
    Slugs: "/mehendi-venues-bhayandar",
    title:"  Mehendi venues in Bhayandar | Shree Balaji  Party Hall",
    description:"Celebrate love in style with our exquisite Banquet for Engagement in Bhayandar. Discover a perfect blend of romance & sophistication creating lifetime memories.",
    keyword:" Mehendi venues in Bhayandar",
    ServicesImage:i72,
    SercicesHeading1:"Mehandi",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our Mehendi venue in Bhayandar, where we skillfully incorporate colorful strands of custom and festivity into your Mehendi ritual. This wonderful pre-wedding event honoring the art of henna and the celebration of love takes place against the breathtaking backdrop of our dining hall. We at the Mehendi venue in Bhayandar are aware of the cultural significance and joy that accompany Mehendi rituals, and we feel privileged to be a part of these special occasions. Enter our charming location, which is furnished with elaborate and vibrant décor to provide the ideal atmosphere for the Mehendi celebrations. Our committed staff makes sure everything runs well and is enjoyable, from customized installations to cuisine that suits your preferences. ",
    SercicesPara2:"At the Mehendi venue in Bhayandar, every little detail is thoughtfully chosen to enhance the atmosphere of your Mehendi celebration. Our dedication to perfection guarantees that your big day will be replete with laughter, cheer, and the coziness of family gatherings. You can rely on us to provide you and your loved ones with a memorable and culturally enriching Mehendi ceremony. Come celebrate art, love, and the excitement of fresh starts with us at the Mehendi venue in Bhayandar.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "SANGEET",
    Slugs: "/top-party-hall-bhayandar-sangeet",
    title:"Top Party Hall in Bhayandar for Sangeet | Shree Balaji Banquet ",
    description:"Elevate your Sangeet ceremony at the Top Party all in Bhayandar. Unmatched ambiance, impeccable service, and unforgettable moments await.",
    keyword:"Top Party Hall in Bhayandar for Sangeet",
    ServicesImage:i71,
    SercicesHeading1:"Sangeet",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to the top banquet for sangeet in Bhayandar, where we create the perfect atmosphere for captivating sangeet rituals that combine dancing, music, and celebration into a joyful symphony. Our dinner hall is a canvas where customs come to life and love is elegantly and passionately celebrated. At the top banquet for sangeet in Bhayandar, we take pride in offering an engaging setting to complement this musical celebration and acknowledge the cultural significance of sangeet rituals.",
    SercicesPara2:"Enter our beautifully crafted venue, complete with tasteful furnishings and all the necessary equipment for entertaining crowds. You can enjoy the celebrations with your loved ones, knowing that every aspect of your Sangeet ceremony has been meticulously planned by our team of experts. Savor the charm of this pre-wedding celebration in the top banquet for sangeet in Bhayandar, where the pleasure of fresh starts is reflected in every note of the music.Our dedication to quality guarantees that your Sangeet ceremony will be a tasteful fusion of tradition and modernity, fostering an environment where rich cultural diversity and chic sophistication coexist. You can rely on us to make your Sangeet ceremony a joyful and unforgettable event full of dancing, laughter, and celebration. As you set out on the path to married bliss, join us at the top banquet for sangeet in Bhayandar to make enduring memories.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "CORPORATE EVENTS",
    Slugs: "/party-hall-corporate-event-bhayandar",
    title:"Party Hall for Corporate Event in Bhayandar | Shree Balaji Banquet  ",
    description:"Host your next corporate event in style at our exquisite Party Hall in Bhayandar. Unparalleled facilities, elegant ambiance, and seamless services.",
    keyword:"Party Hall for Corporate Event in Bhayandar",
    ServicesImage:i35,
    SercicesHeading1:"Corporate Events",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our Banquet for Corporate Event in Bhayandar, where we're reinventing business gatherings with style and effectiveness. For business celebrations, conferences, and professional get-togethers, our adaptable banquet hall offers the ideal environment. At our Banquet for Corporate Event in Bhayandar, we understand the importance of creating a setting that reflects the professionalism and goals of your company.Modern amenities and a dedication to quality make up our facility, which is designed to accommodate a wide range of corporate event requirements. Whether it's an annual conference, product launch, or team-building activity, our skilled staff guarantees flawless execution, including bespoke layouts and audio-visual setups.",
    SercicesPara2:"Enter a setting where sophistication and business collide at our Banquet for Corporate Event in Bhayandar. Our friendly staff is committed to providing excellent service, meeting your unique needs, and ensuring that you and your guests have an enjoyable and productive experience.At Shree Balaji Hall, where sophistication and efficiency merge, every detail is carefully considered to ensure the success of your event. Trust us to make your business meetings, held in the elegant setting of our banquet hall, not only successful but also unforgettable.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "STAY FOR GUEST",
    Slugs: "/party-hall-stay-bhayandar",
    title:"Party Hall with Stay in Bhayandar | Shree Balaji Banquet  Party Hall",
    description:"Make your celebrations extraordinary with our Party Hall With Stay in Bhayandar. Seamlessly transition from events to unforgettable nights in comfort.",
    keyword:"Party Hall with Stay in Bhayandar",
    ServicesImage:"https://www.pingpongmoments.in/blog/wp-content/uploads/2022/09/corporate-events-3.jpg",
    SercicesHeading1:"Stay For Guest",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to the Banquet Hall With Stay in Bhayandar West, a premier homestay facility featuring four air-conditioned rooms that can accommodate up to 25 people. Our establishment extends beyond just hosting events by providing your visitors with a cozy and welcoming stay. At our Banquet Hall With Stay in Bhayandar West, elegance and convenience come together to offer a seamless experience for your guests, whether they are attending a wedding, business function, or any other special occasion.",
    SercicesPara2:"Our accommodation options offer a haven within the tasteful confines of our banquet hall, making them a true home away from home. We ensure that your guests' stay is just as enjoyable and memorable as the event itself through luxurious accommodations and individualized services. Our friendly staff is dedicated to meeting your guests' every need, making them feel valued and at home.Choose our Banquet Hall With a Stay in Bhayandar West for a combined experience where lodging and functions run smoothly together. Rely on us to host your celebrations while ensuring your visitors' comfort and convenience. Come celebrate with us in our banquet hall in Bhayandar West, where each visit is a delightful continuation of our commitment to first-rate hospitality.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
];

export default EventsData;
